import {Component, OnInit} from '@angular/core';
import {FormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Utils} from "../tools/Utils";
import {ApiBoursierService} from "../services/api-boursier.service";
import {ToastTool} from "../tools/toast.tool";
import {ApiBoursier} from "../models/api-boursier";
import {ApiBoursierRegion} from "../models/api-boursier-openId";
import {NgbDateFRParserFormatter} from "../tools/ngb-date-fr-parser-formatter";
import {UserService} from "../services/user.service";

@Component({
  selector: 'app-api-boursier',
  templateUrl: './api-boursier.component.html',
  styleUrls: ['./api-boursier.component.css']
})
export class ApiBoursierComponent implements OnInit{

  result: any;
  resultApiBoursier: ApiBoursier;

  constructor(private userService: UserService, private fb: FormBuilder,
              private apiBoursierService: ApiBoursierService) {
  }

  ngOnInit(): void {

     this.form = this.fb.group({
       ine: this.fb.control(null, [Validators.required])
     });

    this.userService.me().subscribe(u => {
      console.log("");
    });

    this.form2 = this.fb.group({
      given_name: this.fb.control(null, [Validators.required]),
      family_name: this.fb.control(null, [Validators.required]),
      birthdate: this.fb.control(null, [Validators.required])
    });
  }

  form: UntypedFormGroup;
  form2: UntypedFormGroup;

  formSubmited: Boolean;

  get ine() { return this.form.get('ine') }

  get given_name() { return this.form2.get('given_name') }
  get family_name() { return this.form2.get('family_name') }
  get birthdate() { return this.form2.get('birthdate') }


  booleanToFrench(boursier: boolean) {
     return boursier?'Oui':'Non';
  }

  search2() {
    if (!this.form2.valid) {
      Utils.validateAllFormFields(this.form2);
      return;
    }

    const openId = new ApiBoursierRegion();
    openId.given_name = this.form2.get('given_name').value
    openId.family_name = this.form2.get('family_name').value
    const bd = this.form2.get('birthdate').value;
    if (bd.includes('-', 0)) {
      openId.birthdate = bd;
    } else {
      openId.birthdate = bd.substr(6, 4) + '-' + bd.substr(3, 2) + '-' + bd.substr(0, 2);
    }

    console.log(openId);

    this.apiBoursierService.getByOpenId(openId).subscribe({
      next: res => {
        if (res.description) {
          this.result = res.description;
          this.resultApiBoursier = null;
        } else {
          this.result = null;
          this.resultApiBoursier = res;
        }
      },
      error: error => {
        this.resultApiBoursier = null;
        this.result = (error.error && error.error.length > 0 ?error.error[0].error_description : 'Une erreur s\'est produite : ' + error.status);
      }
    });
  }

  search() {

    if (!this.form.valid) {
      Utils.validateAllFormFields(this.form);
      return;
    }

    this.apiBoursierService.getByIne(this.form.get('ine')?.value).subscribe({
      next: res => {
        if (res.description) {
          this.result = res.description;
          this.resultApiBoursier = null;
        } else {
          this.result = null;
          this.resultApiBoursier = res;
        }
      },
      error: error => {
        this.resultApiBoursier = null;
        this.result = (error.error && error.error.length > 0 ?error.error[0].error_description : 'Une erreur s\'est produite : ' + error.status);
      }
    });

  }

}
